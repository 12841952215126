import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Button, Section, Box, Text } from "../../components/Core";

import { device } from "../../utils";

import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";
import svgHeroShape from "../../assets/image/hero-shape-svg.svg";
import Clients from "../landing/Clients";

const ShapeTopLeft = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
`;

const ImgRight = styled(Box)`
  max-width: 80%;
  margin-left: -35px;
  filter: ${({ theme }) => `drop-shadow(0 52px 54px ${theme.colors.shadow})`};
  z-index: 2;
  @media ${device.sm} {
    max-width: unset;
    margin-left: -35px;
    position: relative;
  }
  @media ${device.md} {
    margin-left: -85px;
    position: relative;
  }
`;

const ULStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding-top: 0;
  padding-left: 0;

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #19191b;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;
    margin-bottom: 5px;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 30px;
      min-width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      top: 10px;
      margin-right: 13px;
    }
  }
`;

const BoxStyled = styled(Box)`
  padding-top: 45px;
  padding-bottom: 45px;

  @media ${device.md} {
    padding-top: 85px;
    padding-bottom: 85px;
  }
`;

const AuthorWidget = styled(Box)`
  display: flex;
  flex-direction: column;

  @media ${device.sm} {
    flex-direction: row;
  }

  @media ${device.lg} {
    padding-left: 90px;
    padding-right: 90px;
  }
`;

const AuthorImage = styled(Box)`
  min-width: 80px;
  max-width: 80px;
  height: 80px;
`;

const AuthorText = styled(Box)`
  padding-top: 20px;

  @media ${device.sm} {
    padding-left: 30px;
    padding-top: 0px;
  }
`;

const SportsName = styled.span`
  color: #f04037;
  text-decoration: underline;
`;

const Hero = (props) => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section className="position-relative" pb="0px !important">
        <ShapeTopLeft>
          <img src={svgHeroShape} alt="" className="img-fluid" />
        </ShapeTopLeft>
        <Container>
          <Row className="align-items-center position-relative">
            <Col
              lg="5"
              className="position-static d-none d-lg-block"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-delay="500"
              data-aos-once="true"
            >
              <ImgRight>
                <PreviewCompatibleImage
                  imageInfo={{
                    image: props.image,
                  }}
                />
              </ImgRight>
            </Col>
            <Col lg="7">
              <div
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <Box pt={[4, null, null, 0]} pb={5}>
                  <Title>
                    {props.heading1 + " "}
                    <SportsName>{props.title.toLowerCase()}</SportsName>
                    {" " + props.heading2}
                  </Title>
                  <Box mb={3}>
                    <ULStyled>
                      <li>{props.usp1}</li>
                      <li>{props.usp2}</li>
                      <li>{props.usp3}</li>
                    </ULStyled>
                  </Box>
                  <div className="d-flex flex-column align-items-start pt-2">
                    <a
                      href={"https://" + props.url + "/manage/new"}
                      target="_blank"
                      rel="noopener noreferrer"
                      id="clickNewTournament"
                    >
                      <Button mb={2}>{props.create_tournament}</Button>
                    </a>
                    <Text fontSize={"18px"} color="ash" fontWeight={500}>
                      {/* Interested in a free chapter?{" "}
                      <a href="/" target="blank">
                        <Span color="secondary">Get it now</Span>
                      </a> */}
                    </Text>
                  </div>
                </Box>
              </div>
              <Clients
                heading={props.clients_heading}
                clients={props.clients}
                bg="#fff"
                pb="20px"
                aos
              />
            </Col>
          </Row>
        </Container>
      </Section>
      {props.quote && (
        <BoxStyled>
          <Container>
            <Row className="align-items-center justify-content-center position-relative">
              <Col lg="12" xl="10">
                <AuthorWidget>
                  <AuthorImage
                    data-aos="zoom-in"
                    data-aos-duration="750"
                    data-aos-delay="100"
                    data-aos-once="true"
                  >
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: props.quote_image,
                      }}
                    />
                  </AuthorImage>
                  <AuthorText
                    data-aos="fade-right"
                    data-aos-duration="750"
                    data-aos-delay="200"
                    data-aos-once="true"
                  >
                    <Text color="dark" className="font-italic">
                      {"“" + props.quote + "”"}
                    </Text>
                    <Title variant="card" mt={3}>
                      {props.quote_author}
                    </Title>
                  </AuthorText>
                </AuthorWidget>
              </Col>
            </Row>
          </Container>
        </BoxStyled>
      )}
    </>
  );
};

export default Hero;
