import React from "react";
import Hero from "../sections/sport/Hero";
import PageWrapper from "../components/PageWrapper";
import { Box } from "../components/Core";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Feature from "../sections/sport/Feature";
import Pricing from "../sections/landing/Pricing";
import { useIntl } from "react-intl";

const SportPage = ({ data, location, url, multiCurrency }) => {
  const frontmatter = data.content.frontmatter;
  const intl = useIntl();

  return (
    <>
      <PageWrapper>
        <Hero
          heading1={intl.formatMessage({
            id: "sports.heading1",
            defaultMessage: "Organize your next",
          })}
          heading2={intl.formatMessage({
            id: "sports.heading2",
            defaultMessage: "tournament with Tournify",
          })}
          title={frontmatter.title}
          usp1={frontmatter.usp1}
          usp2={frontmatter.usp2}
          usp3={frontmatter.usp3}
          create_tournament={intl.formatMessage({
            id: "sports.create_tournament",
            defaultMessage: "Create free tournament",
          })}
          image={frontmatter.image}
          clients_heading={intl.formatMessage({
            id: "sports.clients_heading",
            defaultMessage: "Upgrade your tournaments, like they did",
          })}
          clients={frontmatter.clients}
          quote={frontmatter.quote}
          quote_author={frontmatter.quote_author}
          quote_image={frontmatter.quote_image}
          url={url}
        />
        <Feature
          bye_excel={intl.formatMessage({
            id: "sports.bye_excel",
            defaultMessage: "Bye Excel 👋",
          })}
          bye_excel_description={intl.formatMessage({
            id: "sports.bye_excel_description",
            defaultMessage:
              "Get rid of spreadsheets or pen and paper. Create a match schedule in minutes and turn stress into fun.",
          })}
          all_features={intl.formatMessage({
            id: "sports.all_features",
            defaultMessage: "See all features",
          })}
          features={frontmatter.unique_features}
        />
        <Pricing
          online={frontmatter.online}
          data={data.pricing}
          hideSwitch
          location={location}
          langKey={data.content.fields.langKey}
          multiCurrency={multiCurrency}
        />
        <Box className="position-relative overflow-hidden">
          <div className="w-100 img-fluid">
            <PreviewCompatibleImage
              imageInfo={{
                image: frontmatter.sport_image,
              }}
            />
          </div>
        </Box>
      </PageWrapper>
    </>
  );
};

class SportPageWrapper extends React.Component {
  state = {
    url: "",
  };

  componentDidMount() {
    this.setState({ url: window.location.hostname });
  }

  render() {
    const { url } = this.state;

    return <SportPage {...this.props} url={url}/>;
  }
}

export default SportPageWrapper;
